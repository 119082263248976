<div>
    <ngx-slick-carousel class="carousel" 
    #slickModal="slick-carousel" 
    [config]="slideConfig" 
    (init)="slickInit($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <img class="desktop" src="{{ slide.img }}" alt="" width="100%" />
      <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%" />
    </div>
    </ngx-slick-carousel>
  </div>

  <div class="content" #About>
    <div class="wrapper_content">
      <h1 class="left" [ngClass]="{ 'h-active' : isAboutScrolledIntoView }">
        <span>CODELI – Sistemas integrados</span>
      </h1>
      <p class="left" [ngClass]="{ 'h-active' : isAboutScrolledIntoView }">
        <br />
        Ferramenta disponibilizada na plataforma web que fornece todas as informações aos clientes, sobre processos e embarques, em tempo real.
      </p>

      <div class="features">
        <div class="box" *ngFor="let feature of featureList" class="{{ feature['direction'] }}"  [ngClass]="{ 'v-active' : isAboutScrolledIntoView }">
          <div><img class="icon" src="{{ feature['image'] }}" alt="{{ feature['title'] }}"/></div>
          <span class="" [innerHTML]="feature['title']" ></span>
        </div>
      </div>
    </div>
  </div>