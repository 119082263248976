import { Component, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-institucional',
  templateUrl: './institucional.component.html',
  styleUrls: ['./institucional.component.css']
})
export class InstitucionalComponent implements OnInit {
  slides = [
    {
      img: "/assets/images/banners/Codeli-banner-web-INSTITUCIONAL.png",
      img_mobile: "/assets/images/banners/Codeli-banner-mobile-INSTITUCIONAL.png"
    }
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};

  slickInit(e) {
    console.log('slick initialized');
  }

  @ViewChild('About', { static: false })
  private About: ElementRef<HTMLDivElement>;
  isAboutScrolledIntoView: boolean;

  @ViewChild('Qualificacoes', { static: false })
  private Qualificacoes: ElementRef<HTMLDivElement>;
  isQualificacoesScrolledIntoView: boolean;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.About) {
      const rect = this.About.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isAboutScrolledIntoView = topShown && bottomShown;
    }

    if (this.Qualificacoes) {
      const rect = this.Qualificacoes.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isQualificacoesScrolledIntoView = topShown && bottomShown;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
