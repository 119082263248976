
<div>
  <ngx-slick-carousel class="carousel" 
  #slickModal="slick-carousel" 
  [config]="slideConfig" 
  (init)="slickInit($event)">
  <div ngxSlickItem *ngFor="let slide of slides" class="slide">
    <img class="desktop" src="{{ slide.img }}" alt="" width="100%" />
    <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%" />
  </div>
  </ngx-slick-carousel>
</div>

<div class="content" #About>
  <div class="wrapper_content">
    <h1 class="left" [ngClass]="{ 'active-left': isAboutScrolledIntoView }">
      <span>Quem somos?</span>
    </h1>
    <div class="up" [ngClass]="{ 'active-up': isAboutScrolledIntoView }">
      <span>
        <br />
        Somos uma empresa referência em Assessoria Aduaneira e Agenciamento de Cargas.
        <br /><br />
        Com foco em redução de custos ao cliente e sempre preservando pela qualidade dos serviços realizados a Codeli se tornou para algumas indústrias referência.
        <br /><br />
        A Codeli tem a solução adequada de ponta a ponta, com profissionais qualificados e com estrutura e tecnologia atendendo as constantes inovações do mercado.
        <br /><br />
        Codeli, eficiência e qualidade, hoje!
        <br /><br />
      </span>
    </div>
  </div>
</div>

<div class="parallax"></div>

<div class="content indigo" #Qualificacoes>
  <div class="wrapper_content">
    <h1 class="left" [ngClass]="{ 'active-left': isQualificacoesScrolledIntoView }">
      <span>Qualificações</span>
    </h1>
    <div class="qualificacoes up" [ngClass]="{ 'active-up': isQualificacoesScrolledIntoView }">
      <div class="pca-logo">
        <img src="/assets/images/Selo_PCA_member.png" alt="PCA Member"/>
      </div>
      <div class="pca-logo">
        <img src="/assets/images/Selo_IATA.png" alt="PCA Member"/>
      </div>
    </div>
  </div>
</div>