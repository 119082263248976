<div>
    <ngx-slick-carousel class="carousel" 
    #slickModal="slick-carousel" 
    [config]="slideConfig" 
    (init)="slickInit($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <img class="desktop" src="{{ slide.img }}" alt="" width="100%" />
      <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%" />
    </div>
    </ngx-slick-carousel>
  </div>

  <div class="content">
    <div class="wrapper_content">
      <h1>
        <span>Rastreamento dos Cookies</span>
      </h1>
      <span>
        <br />
        Este site armazena cookies em seu computador. Esses cookies são usados para coletar informações sobre como você interage com nosso site e nos permite lembrar de você. Usamos essas informações para melhorar e personalizar sua experiência e para análises e métricas sobre nossos visitantes, tanto nesse site quanto em outras mídias.
        <br /><br />
        Se você recusar, suas informações não serão rastreadas quando você acessar este site. Um cookie simples será usado em seu navegador para lembrar sobre sua preferência de não ser rastreado.
    </span>
    </div>
  </div>