<div>
  <ngx-slick-carousel class="carousel" 
  #slickModal="slick-carousel" 
  [config]="slideConfig" 
  (init)="slickInit($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <img class="desktop" src="{{ slide.img }}" alt="" width="100%">
      <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%">
    </div>
  </ngx-slick-carousel>
</div>

<section #Farmaceutica>
  <div class="content white">
    <div class="wrapper_content">
      <article class="reverse-row">
        <div class="text-reverse right" [ngClass]="{ 'active-right' : isFarmaceuticaScrolledIntoView }">
          <div>
            <h1 class="talign-left">
              <span>
                Farmacêutica
              </span>
            </h1>
            <div>
              Soluções especiais para atender os requisitos regulatórios.<br /><br />Atuando na indústria farmacêutica há mais de 30 anos, a CODELI entende a importância de oferecer soluções logísticas adequadas de confiança, e eficiência.<br /><br />Nossos profissionais são devidamente treinados regularmente e ajudam na implementação de soluções logísticas inovadoras, que atendem todos os requisitos com excelência operacional.<br /><br />Soluções específicas de supply chain, para atender os desafios de seus negócios.<br /><br />A indústria farmacêutica não para de evoluir. Com base em nossa experiência, somos aptos a desenvolver soluções de logísticas sob medida, para atender os requisitos específicos de seus produtos.<ul><li>Produtos farmacêuticos;</li><li>Equipamentos médicos e de diagnóstico;</li><li>Produtos altamente especializados;</li><li>Maquinários e partes e peças;</li><li>Produtos controlados;</li><li>Mercadorias perigosas.</li></ul>
            </div>
    
          </div>
        </div>
    
        <div class="img left" [ngClass]="{ 'active-left': isFarmaceuticaScrolledIntoView }">
          <div class="session-image">
            <img src="/assets/images/industrias/farmaceutica.png" alt="">
          </div>
        </div>
      </article>
    </div>
  </div>
</section>

<section #Automotivo>
  <div class="content darkest-blue">
    <div class="wrapper_content">
      <article class="row">
        <div class="text left" [ngClass]="{ 'active-left' : isAutomotivoScrolledIntoView }">
          <div>
            <h1 class="talign-left">
              <span>
                Automotivo
              </span>
            </h1>
            <div>
              Serviços especializados para os desafios da evolução de sua <em>supply chain</em>;<br /><br />Fornecedores automotivos, montadoras, importadoras, exportadoras, distribuidoras ou provedores de mobilidade... <br /><br />O bom desempenho da logística é um item fundamental para sua competitividade, com requisitos específicos em termos de confiabilidade e qualidade, por isso <strong>garantimos nossa experiência em cada etapa de sua cadeia de suprimentos, em cada etapa do processo</strong>.<br /><br />Análise contínua e adequação das suas soluções logísticas de transporte, nacional e internacional.<br /><br />Desde partes e peças, itens pré-montados, até veículos novos, nossos profissionais desenham e implementam soluções logísticas, para atender aos desafios dos negócios voltados à indústria automotiva.
            </div>
    
          </div>
        </div>
    
        <div class="img right" [ngClass]="{ 'active-right': isAutomotivoScrolledIntoView }">
          <div class="session-image">
            <img src="/assets/images/industrias/automotivo.png" alt="">
          </div>
        </div>
      </article>
    </div>
  </div>
</section>

<section #Food>
  <div class="content dark-blue">
    <div class="wrapper_content">
      <article class="reverse-row">
        <div class="text-reverse right" [ngClass]="{ 'active-right' : isFoodScrolledIntoView }">
          <div>
            <h1 class="talign-left">
              <span>
                Food and Beverage
              </span>
            </h1>
            <div>
              Soluções especiais de logística, para garantir a confiabilidade e eficiência da sua cadeia de suprimentos. <br /><br />Fluxograma de soluções de supply chain personalizadas, para o setor de Alimentos e Bebidas.<br /><br />A visibilidade de ponta-a-ponta em sua cadeia de suprimentos é extremamente importante. Com base na nossa experiência, no setor de Alimentos e Bebidas, desenvolvemos uma solução dedicada para o seu negócio. Fornecemos soluções logísticas competitivas, contribuindo para a sua política de Responsabilidade Social Corporativa.<br /><br />Em um mundo de negócios com rápida mudança e com grandes mercados regulatórios, as cadeias de suprimentos de alimentos e bebidas se tornam cada vez mais complexas, impulsionadas por um público cada vez mais exigente.
            </div>
    
          </div>
        </div>
    
        <div class="img left" [ngClass]="{ 'active-left': isFoodScrolledIntoView }">
          <div class="session-image">
            <img src="/assets/images/industrias/alimenticia.png" alt="">
          </div>
        </div>
      </article>
    </div>
  </div>
</section>

<section #Oleo>
  <div class="content blue">
    <div class="wrapper_content">
      <article class="row">
        <div class="text left" [ngClass]="{ 'active-left' : isOleoScrolledIntoView }">
          <div>
            <h1 class="talign-left">
              <span>
                Óleo e gás
              </span>
            </h1>
            <div>
              Especialização para atender sem limites, os desafios de sua cadeia de suprimentos.<br /><br />Oferecemos soluções completas, eficientes e seguras, com foco na proteção ambiental. <br /><br />Como cada projeto é único, nossos especialistas desenham e monitoram a solução adequada, adaptada ao seu projeto para garantir o sucesso de seu negócio.<br /><br />Gestão especializada de seus projetos através de um contato único conectado diretamente com nossos especialistas;<br /><br />Estudo de viabilidade: nossos especialistas analisam, desenham e implementam soluções considerando as regulamentações atuais, e especificações do produto, no que diz respeito aos assuntos aduaneiros ou qualquer outro assunto específico, que envolve seu projeto como todo;<br /><br />Equipamentos sob demanda para cada tipo de transporte;<br /><br />Transporte multimodal (desde uma pequena embarcação, marítimo, aéreo ou rodoviário) qualquer que seja o peso, tamanho e tipo de produto de seus processos;<br /><br />Otimização e gestão de operações aduaneiras especiais;<br /><br />Monitoramento de operações em tempo real considerando as normas de segurança de cada trecho.
            </div>
    
          </div>
        </div>
    
        <div class="img right" [ngClass]="{ 'active-right': isOleoScrolledIntoView }">
          <div class="session-image">
            <img src="/assets/images/industrias/oleo_gas.png" alt="">
          </div>
        </div>
      </article>
    </div>
  </div>
</section>

<section #Tecnologia>
  <div class="content soft-blue">
    <div class="wrapper_content">
      <article class="reverse-row">
        <div class="text-reverse right" [ngClass]="{ 'active-right' : isTecnologiaScrolledIntoView }">
          <div>
            <h1 class="talign-left">
              <span>
                Tecnologia
              </span>
            </h1>
            <div>
              Soluções que garantem a boa performance, segurança e agilidade de sua cadeia de suprimentos.<br /><br />Produtos delicados e de alto valor agregado, ou ainda de ciclo de vida curto, os requerimentos do setor de tecnologia são muito específicos.<br /><br />Como especialistas em supply chain para o setor de equipamentos de tecnologia, podemos oferecer soluções especializadas para sua logística.
            </div>
    
          </div>
        </div>
    
        <div class="img left" [ngClass]="{ 'active-left': isTecnologiaScrolledIntoView }">
          <div class="session-image">
            <img src="/assets/images/industrias/tecnologia.png" alt="">
          </div>
        </div>
      </article>
    </div>
  </div>
</section>

<section #Cargos>
  <div class="content softest-blue">
    <div class="wrapper_content">
      <article class="row">
        <div class="text left" [ngClass]="{ 'active-left' : isCargosScrolledIntoView }">
          <div>
            <h1 class="talign-left">
              <span>
                Cargas projeto e equipamentos pesados
              </span>
            </h1>
            <div>
              Soluções logísticas eficientes de porta a porta.<br /><br />Seja qual for o seu projeto para transporte: ônibus, máquinas agrícolas, vagões de trem; partes para aviação, mineração, equipamentos de construção, maquinários ou até mesmo barco, nós gerenciamos soluções porta a porta, de confiança, e seguras para atender as necessidades de seus clientes, em escala mundial.<br /><br />Através de nossas soluções logísticas, cobrimos todos os aspectos de sua cadeia de suprimentos, seja a entrega de componentes do estoque para suas plantas, seja a distribuição de produtos acabados, a entrega de peças de reposição ou logística de cargas especiais, até a entrega de mercadorias pesadas e superdimensionadas.
            </div>
    
          </div>
        </div>
    
        <div class="img right" [ngClass]="{ 'active-right': isCargosScrolledIntoView }">
          <div class="session-image">
            <img src="/assets/images/industrias/cargas.png" alt="">
          </div>
        </div>
      </article>
    </div>
  </div>
</section>