<div>
    <ngx-slick-carousel class="carousel" 
    #slickModal="slick-carousel" 
    [config]="slideConfig" 
    (init)="slickInit($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <img class="desktop" src="{{ slide.img }}" alt="" width="100%" />
      <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%" />
    </div>
    </ngx-slick-carousel>
  </div>

  <div class="content" #About>
    <div class="wrapper_content">
      <h1 class="left" [ngClass]="{ 'h-active' : isAboutScrolledIntoView }">
        Performances e KPI’S
      </h1>
      <p class="left" [ngClass]="{ 'h-active' : isAboutScrolledIntoView }">
        <br />
        Métricas que você pode eleger como essenciais para avaliar um processo de sua gestão.
        <br /><br />
        Permite gerar rapidamente vários gráficos de performances (KPI)
      </p>
      <div class="image up" [ngClass]="{ 'v-active' : isAboutScrolledIntoView }">
          <img src="/assets/images/servicos/tempo.png" alt="Performances e KPI’S"/>
      </div>
    </div>
  </div>