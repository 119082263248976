<div>
  <ngx-slick-carousel class="carousel" 
  #slickModal="slick-carousel" 
  [config]="slideConfig" 
  (init)="slickInit($event)">
  <div ngxSlickItem *ngFor="let slide of slides" class="slide">
    <img class="desktop" src="{{ slide.img }}" alt="" width="100%" />
    <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%" />
  </div>
  </ngx-slick-carousel>
</div>

<div class="content">
  <div class="wrapper_content">
    <div class="features">
      <div class="system">
        <div>
          <img src="/assets/images/servicos/codeli-system/codeli-system.png" alt="Codeli System">
        </div>
        
        <div (click)="toggleMenu1()" class="consultoria"></div>
        <div (click)="toggleMenu2()" class="importacao"></div>
        <div (click)="toggleMenu3()" class="siscomex"></div>
        <div (click)="toggleMenu4()" class="agenciamento"></div>
        <div (click)="toggleMenu5()" class="exportacao"></div>
        <div (click)="toggleMenu6()" class="classificacao"></div>
      </div>
      <!-- <div class="box" *ngFor="let feature of featureList">
        <div><img class="icon" src="{{ feature['image'] }}" alt="{{ feature['title'] }}"/></div>
        <span class="" [innerHTML]="feature['title']" ></span>
      </div> -->
    </div>
  </div>
</div>

<div *ngIf="showMenu1">
  <div class="modal" >
    <h3>Consultoria Aduaneira</h3>
    <br />
    <p>Suporte direto ao cliente de forma individual e personalizada, através do esclarecimento de dúvidas, acompanhamento e demais atividades relacionadas ao desembaraço aduaneiro.</p>
  </div>
  <div (click)="toggleMenu1()" class="overlay"></div>
</div>

<div *ngIf="showMenu2">
  <div class="modal" >
    <h3>Importação</h3>
    <br />
    <p>Acompanhamento personalizado desde a finalização do pedido de compra à entrega da mercadoria na fábrica. Atividades como: Classificação da mercadoria, verificação e confecção de Licenciamento de Importação, registro da Declaração de Importação, desembaraço e entrega da mercadoria ao destino final.</p>
  </div>
  <div (click)="toggleMenu2()" class="overlay"></div>
</div>

<div *ngIf="showMenu3">
  <div class="modal" >
    <h3>Habilitação Siscomex </h3>
    <br />
    <p>Registro e Rastreamento da Atuação dos Intervenientes Aduaneiros, habilitação para operar com o Siscomex, obtido em uma unidade da Receita Federal, com quatro tipos de habilitação: ordinária, simplificada, especial e restrita. </p>
  </div>
  <div (click)="toggleMenu3()" class="overlay"></div>
</div>

<div *ngIf="showMenu4">
  <div class="modal" >
    <h3>Agenciamento de Cargas</h3>
    <br />
    <p>Cuidado e atenção com sua carga, de ponta a ponta, a CODELI conta com expertise e uma estrutura de global, tendo como principais objetivos a segurança e qualidade no manuseio de sua carga, nos dedicamos a transformar cada embarque em um embarque bem-sucedida.  É nossa prioridade aprender e entender continuamente as necessidades de nossos clientes, que confiam e acreditam em nossa experiência e nas confiáveis soluções logísticas que oferecemos.  </p>
  </div>
  <div (click)="toggleMenu4()" class="overlay"></div>
</div>

<div *ngIf="showMenu5">
  <div class="modal" >
    <h3>Exportação</h3>
    <br />
    <p>Acompanhamento personalizado desde a confecção de frete junto ao agente de carga ao acompanhamento do embarque até a chegada ao seu destino final. Atividades como: confecção dos documentos de embarque, Declaração Única de Exportação (DU-E), liberação da carga para embarque junto ao SISCOMEX, agendamento de embarque “booking”, acompanhamento do embarque até a chegada ao seu destino final.</p>
  </div>
  <div (click)="toggleMenu5()" class="overlay"></div>
</div>

<div *ngIf="showMenu6">
  <div class="modal" >
    <h3>Classificação tarifária</h3>
    <br />
    <p>Aconselhamento e sugestões de NCM – Nomenclatura Comum do Mercosul.</p>
  </div>
  <div (click)="toggleMenu6()" class="overlay"></div>
</div>