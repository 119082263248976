import { Component, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  slides = [
    { 
      img: "/assets/images/banners/Codeli-banner-web-HOME.png",
      img_mobile: "/assets/images/banners/Codeli-banner-mobile-HOME.png"
    }
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};

  slickInit(e) {
    console.log('slick initialized');
  }

  @ViewChild('Qualidade', { static: false })
  private Qualidade: ElementRef<HTMLDivElement>;
  isQualidadeScrolledIntoView: boolean;

  @ViewChild('Requisitos', { static: false })
  private Requisitos: ElementRef<HTMLDivElement>;
  isRequisitosScrolledIntoView: boolean;

  @ViewChild('Objetivos', { static: false })
  private Objetivos: ElementRef<HTMLDivElement>;
  isObjetivosScrolledIntoView: boolean;

  @ViewChild('Responsabilidade', { static: false })
  private Responsabilidade: ElementRef<HTMLDivElement>;
  isResponsabilidadeScrolledIntoView: boolean;

  @ViewChild('Contratar', { static: false })
  private Contratar: ElementRef<HTMLDivElement>;
  isContratarScrolledIntoView: boolean;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.Qualidade) {
      const rect = this.Qualidade.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isQualidadeScrolledIntoView = topShown && bottomShown;
    }

    if (this.Requisitos) {
      const rect = this.Requisitos.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isRequisitosScrolledIntoView = topShown && bottomShown;
    }

    if (this.Objetivos) {
      const rect = this.Objetivos.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isObjetivosScrolledIntoView = topShown && bottomShown;
    }

    if (this.Responsabilidade) {
      const rect = this.Responsabilidade.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isResponsabilidadeScrolledIntoView = topShown && bottomShown;
    }

    if (this.Contratar) {
      const rect = this.Contratar.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isContratarScrolledIntoView = topShown && bottomShown;
    }
  }

  constructor() { }

  ngOnInit(): void {

  }

}
