<div>
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <img class="desktop" src="{{ slide.img }}" alt="" width="100%" />
      <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%" />
    </div>
  </ngx-slick-carousel>
</div>

<section #About>
  <div class="content darkest-blue">
    <div class="wrapper_content">
      <article>
        <h1 class="left" [ngClass]="{ 'active-left' : isAboutScrolledIntoView }">
          <span>
            Desembaraço Aduaneiro
          </span>
        </h1>
        <div class="up" [ngClass]="{ 'active-up' : isAboutScrolledIntoView }">
          <br />
          O processo de desembaraço aduaneiro ocorre sempre que você importa um produto e serve para verificar se as mercadorias estão regularizadas. Esse processo refere-se à chegada dos produtos em território brasileiro. O desembaraço aduaneiro tem por finalidade atender e cumprir as normas aduaneiro-sanitárias nos países de origem e destino.
          <br /><br />
          É preciso cumprir todos os trâmites burocráticos para ter acesso a produtos regularizados. Sendo assim, essa é uma etapa essencial para realizar uma compra ou venda internacional.
          <br /><br />
          No Brasil, os desembaraços aduaneiros de importação e exportação são processados com base nas declarações formuladas pelo importador, exportador ou seus Representantes Legais, via sistema SISCOMEX. No caso da importação, mediante à informação dos dados exigidos pelo sistema, os tributos devidos serão apurados e recolhidos.
          <br /><br />
          Disponibilizamos funcionários, para o atendimento direto ao cliente. Prestando total suporte no FUP (Follow Up) diário, envio e recebimento de documentação e coordenação dos processos. Contamos com sistema informatizado de acompanhamento em tempo real dos processos e com o envio de relatórios personalizados, de maneira a proporcionar aos nossos clientes as informações e status de cada processo em andamento.
          <br /><br />
        </div>
      </article>
    </div>
  </div>
</section>

<section #Expertise>
  <div class="content dark-blue">
    <div class="wrapper_content">
      <article>
        <h2 class="right" [ngClass]="{ 'active-right' : isExpertiseScrolledIntoView }">
          Nossa expertise
        </h2>

        <div class="down" [ngClass]="{ 'active-down' : isExpertiseScrolledIntoView }">
          <br />
          <ul>
            <li>Presença nos principais portos e aeroportos do país;</li>
            <li>Excelente network com diversos órgãos anuentes: ANVISA, MAPA, EXÉRCITO, DECEX, POLÍCIA FEDERAL, CNPQ, INMETRO, entre outros;</li>
            <li>Expertise em todos os procedimentos e enquadramento da ANVISA RDC 81/2008 e RDC 208/2018.</li>
            <li>Automatização de pedidos e controle de indicadores de desempenho;</li>
            <li>Consultoria aduaneira completa e dedicada;</li>
            <li>Liberação de hemoderivados, reagentes, produtos para saúde, pesquisa científica e qualquer produto de natureza especial.</li>
            <li>Análise de NCM e Tratamento Administrativo ideal para todos os tipos de carga.</li>
          </ul>
          <br /><br />
        </div>
      </article>
    </div>
  </div>
</section>

<section #Service>
  <div class="content blue">
    <div class="wrapper_content">
      <article>
        <h2 class="left" [ngClass]="{ 'active-left' : isServiceScrolledIntoView }">
          Importação
        </h2>

        <div class="up" [ngClass]="{ 'active-up' : isServiceScrolledIntoView }">
          <br />
          <ul>
            <li>Experiência nos mais diversos setores: farmacêutico, veterinário, químico, eletrônico, máquinas e equipamentos, aeronáutico, alimentício, energia, carga projeto, entre outros;</li>
            <li>Estrutura de atendimento personalizado através de grupos de trabalho, de acordo com as características de cada operação;</li>
            <li>Controle dedicado de todas as licenças de importação;</li>
            <li>Utilização de KPI para garantir uma gestão e controle operacional preciso;</li>
            <li>Sistema dedicado que controla e automatiza as licenças de importação, através de robôs.</li>
          </ul>
          <br /><br />
        </div>
      </article>
    </div>
  </div>
</section>

<section #Entrega>
  <div class="content soft-blue">
    <div class="wrapper_content">
      <article>
        <h2 class="right" [ngClass]="{ 'active-right' : isEntregaScrolledIntoView }">
          Exportação
        </h2>

        <div class="down" [ngClass]="{ 'active-down' : isEntregaScrolledIntoView }">
          <br />
          <ul>
            <li>Domínio na emissão dos documentos relacionados ao desembaraço das cargas de exportação (fatura comercial, packing list, AWB, BL, pré-alertas ao importador);</li>
            <li>Expertise nas rotinas e procedimentos inerentes a Certificação de Origem, junto as mais diversas entidades certificadoras;</li>
            <li>Expertise e Gestão de Declarações de Origem (inclusão e controle de vencimento);</li>
            <li>Total habilidade nos sistemas provenientes do Portal Único, para efetivação/deferimento dos Registros de Exportação, bem como elaboração da Declaração de Exportação (DU-E);</li>
            <li>Acompanhamento e medição através de KPI e gestão visual, com a utilização de Dashboards.</li>
          </ul>
        </div>
      </article>
    </div>
  </div>
</section>