<div class="pre_footer">
    <div class="footer_wrapper">
        <div class="content_footer">
            <!-- <div class="footer_session">
                <h2>Sobre a Codeli</h2>
                <p>
                    Somos uma empresa especializada em Assessoria Aduaneira e Agenciamento de Cargas. 
                    <br /><br />
                    Com foco em redução de custos ao cliente e sempre preservando pela qualidade dos serviços realizados, a Codeli se tornou para algumas indústrias referência em assessoria aduaneira a logística. 
                    <br /><br />
                    A Codeli tem a solução certa e adequada, de ponta a ponta, com profissionais qualificados e com estrutura e tecnologia adequada atendendo as constantes inovações do mercado. 
                    <br /><br />
                    Codeli, eficiência e qualidade, hoje!
                </p>
            </div> -->
            <div class="footer_session">
                <h2>Nossos endereços</h2>
                <p>
                    <strong>Sede São Paulo</strong><br />
                    Rua Vilela, 652 -  26º andar, sala 2601 a 2604<br />
                    Tatuapé, São Paulo – SP, CEP: 03314-000 <br />
                    Telefone: +55 11 3113-2770 <br />
                    <br /><br />
                    <strong>Filial Santos</strong><br />
                    Av. Conselheiro Nébias, 444 - 6º andar, conj. 606<br />
                    Encruzilhada, Santos – SP, CEP: 11045-000<br />
                    Telefone: +55 13 3208-8880 <br />
                </p>
            </div>
            <div class="footer_session">
                <h2>Sitemap</h2>
                <ul class="sitemap">
                    <li><a routerLink="./institucional">A Codeli</a></li>
                    <li><a routerLink="./servicos">Serviços</a></li>
                    <li><a routerLink="./industria">Expertise</a></li>
                    <li><a routerLink="./contato">Contato</a></li>
                    <li><a routerLink="./canal-de-denuncias">Ouvidoria</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="copyright">
    <span>Copyright © 2022 Codeli</span>
</div>

<div class="cookies" [ngClass]="{'active': cookieActive}">
    <div class="footer_wrapper">
       <div class="content_footer">
            <div class="content">
                <p>Este site utiliza cookies para realização de análises estatísticas acerca de sua utilização. 
                <br />Não são coletados dados pessoais por meio de cookies.</p>
                Veja nossa <a class="link" routerLink="./politicas/rastreamento-cookies">Política de Rastreamento de Cookies</a>
            </div>
    
            <div class="buttons">
              <button class="btn close" (click)="closeCookie()">Entendi</button>
            </div>
       </div>
      </div>
  </div>