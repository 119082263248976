<div class="content" id="navbar">
    <div class="header_wrapper">
        <div class="brand">
            <a class="desktop" routerLink="/">
                <img src="/assets/images/codeli_logo.png"/>
            </a>
            <a class="mobile" routerLink="/">
                <img src="/assets/images/codeli_logo-icon.png"/>
            </a>
        </div>

        <div class="desktop">
            <ul class="menu">
                <li>
                    <a class="button" routerLink="./institucional" routerLinkActive="activebutton">A Codeli</a>
                </li>
                <li>
                    <a class="button" routerLink="./servicos" routerLinkActive="activebutton">Serviços</a>
                    <ul class="submenu">
                        <li>
                            <a class="button" routerLink="./servicos/transporte-aereo" routerLinkActive="activebutton">Transporte Aéreo</a>
                        </li>
                        <li>
                            <a class="button" routerLink="./servicos/transporte-maritimo" routerLinkActive="activebutton">Transporte Marítimo</a>
                        </li>
                        <li>
                            <a class="button" routerLink="./servicos/desembaraco-aduaneiro" routerLinkActive="activebutton">Desembaraço Aduaneiro</a>
                        </li>
                        <li>
                            <a class="button" routerLink="./servicos/codeli-system" routerLinkActive="activebutton">CODELI – Sistemas integrados</a>
                        </li>
                        <li>
                            <a class="button" routerLink="./servicos/performance-kpi" routerLinkActive="activebutton">Performances e KPI’S</a>
                        </li>
                        <li>
                            <a class="button" routerLink="./servicos/modelo-po-management" routerLinkActive="activebutton">PO Management</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="button" routerLink="./industria" routerLinkActive="activebutton">Expertise</a>
                </li>
                <li>
                    <a class="button" routerLink="./contato" routerLinkActive="activebutton">Contato</a>
                </li>
                <li>
                    <a class="button" routerLink="./canal-de-denuncias" routerLinkActive="activebutton">Ouvidoria</a>
                </li>
            </ul>
        </div>

        <div class="mobile">
            <button class="menu-btn" (click)="toggleNavbar()" [innerHtml]="menuIcon"></button>
        </div>
    </div>
    <div class="mobile-menu" [style.display]="navbarOpened ? 'block' : 'none'" >
        <ul class="mobileMenu">
            <li>
                <a class="button" routerLink="./institucional" routerLinkActive="activebutton" (click)="toggleNavbar()">A Codeli</a>
            </li>
            <li>
                <a class="button" routerLink="./servicos" routerLinkActive="activebutton" (click)="toggleSubmenuServicos()">Serviços</a>
                <ul class="submenuMobile" [style.display]="submenuServicosOpened ? 'block' : 'none'">
                    <li>
                        <a class="button" routerLink="./servicos/transporte-aereo" routerLinkActive="activebutton" (click)="toggleNavbar()">Transporte Aéreo</a>
                    </li>
                    <li>
                        <a class="button" routerLink="./servicos/transporte-maritimo" routerLinkActive="activebutton" (click)="toggleNavbar()">Transporte Marítimo</a>
                    </li>
                    <li>
                        <a class="button" routerLink="./servicos/desembaraco-aduaneiro" routerLinkActive="activebutton" (click)="toggleNavbar()">Desembaraço Aduaneiro</a>
                    </li>
                    <li>
                        <a class="button" routerLink="./servicos/codeli-system" routerLinkActive="activebutton" (click)="toggleNavbar()">CODELI – Sistemas integrados</a>
                    </li>
                    <li>
                        <a class="button" routerLink="./servicos/performance-kpi" routerLinkActive="activebutton" (click)="toggleNavbar()">Performances e KPI’S</a>
                    </li>
                    <li>
                        <a class="button" routerLink="./servicos/modelo-po-management" routerLinkActive="activebutton" (click)="toggleNavbar()">PO management</a>
                    </li>
                </ul>
            </li>
            <li>
                <a class="button" routerLink="./industria" routerLinkActive="activebutton" (click)="toggleNavbar()">Expertise</a>
            </li>
            <li>
                <a class="button" routerLink="./contato" routerLinkActive="activebutton" (click)="toggleNavbar()">Contato</a>
            </li>
            <li>
                <a class="button" routerLink="./canal-de-denuncias" routerLinkActive="activebutton" (click)="toggleNavbar()">Ouvidoria</a>
            </li>
        </ul>
    </div>
</div>
