<div #testDiv class="puzzle">
    <div class="left" [ngClass]="{ 'active-left': isTestDivScrolledIntoView }">
        <h2>VISÃO</h2>
        <div class="left-top"></div>
        <div class="left-bottom"></div>
    </div>
    <div class="right" [ngClass]="{ 'active-right': isTestDivScrolledIntoView }">
        Ser uma empresa brasileira referência de qualidade na prestação de serviços logísticos nacionais e internacionais e desembaraço aduaneiro, no segmento de atuação, com transparência e segurança, criando um vínculo com nossos clientes e parceiros comerciais.
        <div class="bottom"></div>
    </div>
    
</div>