import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rastreamento-de-cookies',
  templateUrl: './rastreamento-de-cookies.component.html',
  styleUrls: ['./rastreamento-de-cookies.component.css']
})
export class RastreamentoDeCookiesComponent implements OnInit {
  language: any = localStorage.getItem('language');

  slides = [
    {
      img: "/assets/images/banners/Codeli-banner-web-COOKIES.png",
      img_mobile: "/assets/images/banners/Codeli-banner-mobile-COOKIES.png"
    }
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};

  slickInit(e) {
    console.log('slick initialized');
  }

  constructor() { }

  ngOnInit(): void {
  }

}
