import { Component, OnInit } from '@angular/core';

declare var wheelnav: any;

@Component({
  selector: 'app-modelo-po-management',
  templateUrl: './modelo-po-management.component.html',
  styleUrls: ['./modelo-po-management.component.css']
})
export class ModeloPoManagementComponent implements OnInit {
  showMenu1: boolean = false;
  showMenu2: boolean = false;
  showMenu3: boolean = false;
  showMenu4: boolean = false;
  showMenu5: boolean = false;
  showMenu6: boolean = false;

  slides = [
    {
      img: "/assets/images/banners/Codeli-banner-web-TRANSPORTE_AEREO.png",
      img_mobile: "/assets/images/banners/Codeli-banner-mobile-TRANSPORTE_AEREO.png"
    }
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};

  slickInit(e) {
    console.log('slick initialized');
  }  

  constructor() { }

  ngOnInit(): void {
  }

  toggleMenu1 () {
    this.showMenu1 = !this.showMenu1;
  }
  toggleMenu2 () {
    this.showMenu2 = !this.showMenu2;
  }
  toggleMenu3 () {
    this.showMenu3 = !this.showMenu3;
  }
  toggleMenu4 () {
    this.showMenu4 = !this.showMenu4;
  }
  toggleMenu5 () {
    this.showMenu5 = !this.showMenu5;
  }
  toggleMenu6 () {
    this.showMenu6 = !this.showMenu6;
  }

}
