import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.css']
})
export class ServicosComponent implements OnInit {
    slides = [
    {img: "/assets/images/banners/Codeli-banner-web-SERVICOS.png"}
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};

  slickInit(e) {
    console.log('slick initialized');
  }

  servicos = [
    {image: "/assets/images/servicos/assessoria_aduaneira.png", title: "Assessoria Aduaneira", content: "Importação e exportação<br/>Consumo<br/>Admissão temporária<br/>Entreposto Aduaneiro<br/>Drawback"},
    {image: "/assets/images/servicos/transporte_internacional_aereo.png", title: "Transporte internacional aéreo", content: "Carga seca<br/>Carga refrigerada<br/>Carga perigosa<br/>Consolidada"},
    {image: "/assets/images/servicos/transporte_maritimo.png", title: "Transporte marítimo", content: "Bulk & break bulk<br/>FCL<br/>LCL<br/>Sea Air<br/>Cross docking<br/>FCL reefer"},
    {image: "/assets/images/servicos/transporte_rodoviario.png", title: "Transporte rodoviário", content: "Cuidamos de todo o processo logístico, incluindo no transporte rodoviário, pronto para customizar os planos de acordo com cada caso."},
    {image: "/assets/images/servicos/carga_projeto.png", title: "Carga projeto", content: "Estudos logísticos para projetos especiais"},
    {image: "/assets/images/servicos/tecnologia.png", title: "Tecnologia", content: "Contamos com softwares 100% integrados, que ampliam a visão do processo como todo."}
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
