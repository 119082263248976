import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  navbarOpened: boolean = false;
  menuIcon: string = '<i class="fas fa-bars"></i>';
  submenuServicosOpened: boolean = false;
  submenuServicosIcon: string = '<i class="fas fa-bars"></i>';
  submenuPoliticasOpened: boolean = false;
  submenuPoliticasIcon: string = '<i class="fas fa-bars"></i>';

  constructor(private router: Router){
    console.log(router.url);
  }

  ngOnInit(): void {
  }

  toggleNavbar() {
    this.navbarOpened = !this.navbarOpened;
    if (this.navbarOpened == true) {
      this.menuIcon = '<i class="fas fa-times"></i>';
    } else {
      this.menuIcon = '<i class="fas fa-bars"></i>';
    }
  }

  toggleSubmenuServicos() {
    this.submenuServicosOpened = !this.submenuServicosOpened;
    if (this.submenuServicosOpened == true) {
      this.submenuServicosIcon = '<i class="fas fa-times"></i>';
    } else {
      this.submenuServicosIcon = '<i class="fas fa-bars"></i>';
    }
  }

  toggleSubmenuPoliticas() {
    this.submenuPoliticasOpened = !this.submenuPoliticasOpened;
    if (this.submenuPoliticasOpened == true) {
      this.submenuPoliticasIcon = '<i class="fas fa-times"></i>';
    } else {
      this.submenuPoliticasIcon = '<i class="fas fa-bars"></i>';
    }
  }
}
