import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CookieModule } from 'ngx-cookie';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { InstitucionalComponent } from './pages/institucional/institucional.component';
import { ServicosComponent } from './pages/servicos/servicos.component';
import { IndustriaComponent } from './pages/industria/industria.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { HomeComponent } from './pages/home/home.component';
import { CanalDeDenunciasComponent } from './pages/canal-de-denuncias/canal-de-denuncias.component';
import { PoliticasComponent } from './pages/politicas/politicas.component';
import { ChartsModule } from 'ng2-charts';
import { TransporteAereoComponent } from './pages/servicos/transporte-aereo/transporte-aereo.component';
import { TransporteMaritimoComponent } from './pages/servicos/transporte-maritimo/transporte-maritimo.component';
import { DesembaracoAduaneiroComponent } from './pages/servicos/desembaraco-aduaneiro/desembaraco-aduaneiro.component';
import { CodeliSystemComponent } from './pages/servicos/codeli-system/codeli-system.component';
import { PerformanceKpiComponent } from './pages/servicos/performance-kpi/performance-kpi.component';
import { ModeloPoManagementComponent } from './pages/servicos/modelo-po-management/modelo-po-management.component';
import { RastreamentoDeCookiesComponent } from './pages/politicas/rastreamento-de-cookies/rastreamento-de-cookies.component';
import { PoliticaDeQualidadeComponent } from './pages/politicas/politica-de-qualidade/politica-de-qualidade.component';
import { MissaoComponent } from './pages/home/components/missao/missao.component';
import { VisaoComponent } from './pages/home/components/visao/visao.component';
import { ValoresComponent } from './pages/home/components/valores/valores.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'institucional', component: InstitucionalComponent },
  { path: 'servicos', redirectTo: '', pathMatch: 'full' },
  { path: 'servicos/transporte-aereo', component: TransporteAereoComponent },
  { path: 'servicos/transporte-maritimo', component: TransporteMaritimoComponent },
  { path: 'servicos/desembaraco-aduaneiro', component: DesembaracoAduaneiroComponent },
  { path: 'servicos/codeli-system', component: CodeliSystemComponent },
  { path: 'servicos/performance-kpi', component: PerformanceKpiComponent },
  { path: 'servicos/modelo-po-management', component: ModeloPoManagementComponent },
  { path: 'industria', component: IndustriaComponent },
  { path: 'contato', component: ContatoComponent },
  { path: 'canal-de-denuncias', component: CanalDeDenunciasComponent },
  { path: 'politicas', redirectTo: '', pathMatch: 'full' },
  { path: 'politicas/rastreamento-cookies', component: RastreamentoDeCookiesComponent },
  {
    path: '**', component: AppComponent
  }

];


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    InstitucionalComponent,
    ServicosComponent,
    IndustriaComponent,
    ContatoComponent,
    HomeComponent,
    CanalDeDenunciasComponent,
    PoliticasComponent,
    ModeloPoManagementComponent,
    TransporteAereoComponent,
    TransporteMaritimoComponent,
    DesembaracoAduaneiroComponent,
    CodeliSystemComponent,
    PerformanceKpiComponent,
    RastreamentoDeCookiesComponent,
    PoliticaDeQualidadeComponent,
    MissaoComponent,
    VisaoComponent,
    ValoresComponent
  ],
  exports: [RouterModule],
  imports: [
    BrowserModule,
    SlickCarouselModule,
    RouterModule.forRoot(routes),
    ChartsModule,
    CookieModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
