import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  cookieAccepted: boolean = false;
  cookieActive = false;

  constructor(private cookieService: CookieService) { 
    this.cookieAccepted = Boolean(cookieService.get('cookieAccepted'));
    this.cookieActive = !this.cookieAccepted;
  }

  ngOnInit(): void {
    console.log(this.cookieAccepted);
    console.log(this.cookieActive);
  }

  closeCookie() {
    this.cookieService.put('cookieAccepted', 'true');
    this.cookieActive = false;
  }

  removeCookies() {
    this.cookieService.remove('cookieAccepted');
  }
}
