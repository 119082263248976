<div>
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <img class="desktop" src="{{ slide.img }}" alt="" width="100%" />
      <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%" />
    </div>
  </ngx-slick-carousel>
</div>

<section #About>
  <div class="content darkest-blue">
    <div class="wrapper_content">
      <article>
        <h1 class="left" [ngClass]="{ 'active-left' : isAboutScrolledIntoView }">
          <span>
            Transporte Marítimo
          </span>
        </h1>
        <div class="up" [ngClass]="{ 'active-up' : isAboutScrolledIntoView }">
          <br />
          O transporte marítimo é parte vital da economia Global, representando 80% de todo o comércio, e é pensando nisso que a CODELI oferece soluções de frete marítimo com uma variedade de opções. Quando se trata de frete marítimo, você pode escolher entre FCL (Full Container Load), LCL (Less than Container Load) ou uma combinação de Sea-Air.
          <br /><br />
          Nossa experiência também abrange Carga Superdimensionada e de Projeto, Breakbulk, Manuseio de Mercadorias Perigosas, Consolidação do Comprador, Seguro de Transporte e muito mais. Nossos serviços de frete marítimo também cobrem todo o processo de transporte de carga. Seja qual for o grupo de serviços de frete marítimo que você escolher, nós o ajudaremos a alcançar um equilíbrio excepcional entre tempo e custo para atender às suas necessidades, oferecendo produtos e serviços de qualidade.
          <br /><br />
        </div>
      </article>
    </div>
  </div>
</section>

<section #Expertise>
  <div class="content dark-blue">
    <div class="wrapper_content">
      <article>
        <h2 class="right" [ngClass]="{ 'active-right' : isExpertiseScrolledIntoView }">
          Nossa expertise
        </h2>

        <div class="down" [ngClass]="{ 'active-down' : isExpertiseScrolledIntoView }">
          <br />
          <ul>
            <li><strong>PO management:</strong> Gestão do pedido do cliente desde a criação da PO até a entrega no destino final;</li>
            <li><strong>Follow up:</strong> Acompanhamento voltado à qualidade e transparência da informação ao cliente;</li>
            <li><strong>Acompanhamento de Line-ups:</strong> Atualização e acompanhamento diário, e envio do Line-up de navios dos Portos e Terminais brasileiros;</li>
            <li><strong>Follow-ups diários</strong> antes da atracação e durante a operação de descarga;</li>
            <li><strong>Assessoria Aduaneira:</strong> Consultoria especializada aos clientes que operam em todos os portos no Brasil e no mundo.</li>

          </ul>
          <br /><br />
        </div>
      </article>
    </div>
  </div>
</section>

<section #Service>
  <div class="content blue">
    <div class="wrapper_content">
      <article>
        <h2 class="left" [ngClass]="{ 'active-left' : isServiceScrolledIntoView }">
          Nossos principais serviços
        </h2>

        <div class="up" [ngClass]="{ 'active-up' : isServiceScrolledIntoView }">
          <br />
          <ul>
            <li>FCL and LCL</li>
            <li>Bulk and Breakbulk</li>
            <li>Multi-Modal (Crossdocking)</li>
            <li>Cabotagem</li>
            <li>Sea-Air ou Air-Sea</li>
            <li>Transporte Rodoviário Gerenciamento de embarque Carga Reefer;</li>
            <li>Ro-Ro</li>
            <li>Carga Projeto</li>
          </ul>
          <br /><br />
        </div>
      </article>
    </div>
  </div>
</section>

<section #Entrega>
  <div class="content soft-blue">
    <div class="wrapper_content">
      <article>
        <h2 class="right" [ngClass]="{ 'active-right' : isEntregaScrolledIntoView }">
          Serviços de entrega
        </h2>

        <div class="down" [ngClass]="{ 'active-down' : isEntregaScrolledIntoView }">
          <br />
          <ul>
            <li>Porta a Porto</li>
            <li>Porto a Porto</li>
            <li>Porto a Porta</li>
            <li>Porta a Porta</li>
          </ul>
        </div>
      </article>
    </div>
  </div>
</section>