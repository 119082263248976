<div>
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <img class="desktop" src="{{ slide.img }}" alt="" width="100%" />
      <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%" />
    </div>
  </ngx-slick-carousel>
</div>

<section #About>
  <div class="content darkest-blue">
    <div class="wrapper_content">
      <article>
        <h1 class="left" [ngClass]="{ 'active-left' : isAboutScrolledIntoView }">
          <span>
            Transporte Aéreo
          </span>
        </h1>
        <div class="up" [ngClass]="{ 'active-up' : isAboutScrolledIntoView }">
          <br />
          Temos a solução adequada para o transporte de produtos que requerem um tratamento especial e de qualidade, seja no que diz respeito à temperatura, seja carga perigosa, carga controlada ou não, carga oversize tudo em conformidade com as exigências atuais do mercado nacional e internacional.
          <br /><br />
          Seguimos todos os protocolos para o transporte das mercadorias, conforme regem os regulamentos GDP para produtos farmacêuticos, produtos perecíveis, e os regulamentos IATA e das demais organizações regulamentadoras.
          <br /><br />
        </div>
      </article>
    </div>
  </div>
</section>

<section #Expertise>
  <div class="content dark-blue">
    <div class="wrapper_content">
      <article>
        <h2 class="right" [ngClass]="{ 'active-right' : isExpertiseScrolledIntoView }">
          Nossa expertise
        </h2>

        <div class="down" [ngClass]="{ 'active-down' : isExpertiseScrolledIntoView }">
          <br />
          <ul>
            <li>
              <strong>PO management:</strong> gestão do pedido do cliente desde a criação da PO até a entrega no destino final;
            </li>
            <li>
              <strong>Follow up:</strong> voltado à qualidade e transparência da informação ao cliente;
            </li>
            <li>
              <strong>Estudo logístico:</strong> para o transporte de Carga Geral, Carga Perigosa (DGR), Carga Perecível, Carga com controle de temperatura e cargas projeto;
            </li>
            <li>
              <strong>Operações customizáveis.</strong>
            </li>
          </ul>
          <br /><br />
        </div>
      </article>
    </div>
  </div>
</section>

<section #Service>
  <div class="content blue">
    <div class="wrapper_content">
      <article>
        <h2 class="left" [ngClass]="{ 'active-left' : isServiceScrolledIntoView }">
          Nossos principais serviços
        </h2>

        <div class="up" [ngClass]="{ 'active-up' : isServiceScrolledIntoView }">
          <br />
          <ul>
            <li>
              <strong>Consolidação:</strong> Oferecemos serviços de consolidação programada de algumas rotas pré-definidas com tempos de trânsito previsíveis, com economia de custo substancial em relação aos serviços tradicionais;
            </li>
            <li>
              <strong>Priority Service:</strong> Se o tempo é essencial, nosso Serviço Prioritário é uma opção adequada. Operamos sempre com o melhor voo disponível: com voo direto ou a rota mais rápida para o seu destino final;
            </li>
            <li>
              <strong>Temperature Control:</strong> Serviços de temperatura controlada para produtos farmacêuticos e perecíveis;
            </li>
            <li>
              <strong>Serviços de fretamento:</strong> Através de contratos específicos com os principais players do mercado, a Codeli oferece fretamento de aeronaves em/para qualquer rota, seja ela com destino nacional ou rotas com origem e destinos internacionais;
            </li>
            <li>
              <strong>Rastreamento de embarques:</strong> Através de nosso webtracking é possível ter uma visão geral dos embarques com acesso a todos os documentos e informações;
            </li>
            <li>
              <strong>Embarque com equipamentos especiais:</strong> Temos expertise em envios aéreos com equipamentos especiais como envirotainer ou carga com embalagem oversize apropriada para o transporte aéreo.
            </li>
          </ul>
          <br /><br />
        </div>
      </article>
    </div>
  </div>
</section>

<section #Entrega>
  <div class="content soft-blue">
    <div class="wrapper_content">
      <article>
        <h2 class="right" [ngClass]="{ 'active-right' : isEntregaScrolledIntoView }">
          Serviços de entrega
        </h2>

        <div class="down" [ngClass]="{ 'active-down' : isEntregaScrolledIntoView }">
          <br />
          <ul>
            <li>Porta a Aeroporto</li>
            <li>Aeroporto a Aeroporto</li>
            <li>Aeroporto a Porta</li>
            <li>Porta a Porta</li>
          </ul>
        </div>
      </article>
    </div>
  </div>
</section>