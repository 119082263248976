import { Component, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-canal-de-denuncias',
  templateUrl: './canal-de-denuncias.component.html',
  styleUrls: ['./canal-de-denuncias.component.css']
})
export class CanalDeDenunciasComponent implements OnInit {
  success = false;
  
  public sendEmail(e: Event) {
    e.preventDefault();
    emailjs.sendForm('service_ufa8r34', 'template_mumqv83', e.target as HTMLFormElement, 'user_Hr2eo5Jkg02jFt2L2Ebwo')
      .then((result: EmailJSResponseStatus) => {
        if (result.text === 'OK') {
          this.success = true;
        }
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

  slides = [
    {
      img: "/assets/images/banners/Codeli-banner-web-CANAL_DENUNCIAS.png",
      img_mobile: "/assets/images/banners/Codeli-banner-mobile-CANAL_DENUNCIAS.png"
    }
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};

  slickInit(e) {
    console.log('slick initialized');
  }

  constructor() { }

  ngOnInit(): void {
  }

  @ViewChild('Form', { static: false })
  private Form: ElementRef<HTMLDivElement>;
  isFormScrolledIntoView: boolean;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.Form) {
      const rect = this.Form.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isFormScrolledIntoView = topShown && bottomShown;
    }
  }
}
