import { Component, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-missao',
  templateUrl: './missao.component.html',
  styleUrls: ['./missao.component.css']
})
export class MissaoComponent implements OnInit {

  @ViewChild('testDiv', { static: false })
  private testDiv: ElementRef<HTMLDivElement>;
  isTestDivScrolledIntoView: boolean;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.testDiv) {
      const rect = this.testDiv.nativeElement.getBoundingClientRect();
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isTestDivScrolledIntoView = bottomShown;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
