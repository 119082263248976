<div #testDiv class="puzzle">
    <div class="left" [ngClass]="{ 'active-left': isTestDivScrolledIntoView }">
        <h2>VALORES</h2>
        <div class="left-top"></div>
    </div>
    <div class="right" [ngClass]="{ 'active-right': isTestDivScrolledIntoView }">
        <ul>
            <li> Foco no cliente;</li>
            <li> Atenção aos detalhes;</li>
            <li> Agimos com propósito;</li>
            <li> Comprometimento;</li>
        </ul>

        <ul>
            <li> Qualidade;</li>
            <li> Ética;</li>
            <li> Transparência;</li>
            <li> Inovação;</li>
        </ul>

        <ul>
            <li> Sustentabilidade;</li>
            <li> Responsabilidade social;</li>
            <li> Paixão pelo que fazemos.</li>
        </ul>


        <div class="right-top"></div>
    </div>
</div>