<div>
    <ngx-slick-carousel class="carousel" 
    #slickModal="slick-carousel" 
    [config]="slideConfig" 
    (init)="slickInit($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <img class="desktop" src="{{ slide.img }}" alt="" width="100%" />
      <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%" />
    </div>
    </ngx-slick-carousel>
  </div>

  <div class="content">
    <div class="wrapper_content">
      <h1>
        <span>Política de qualidade</span>
      </h1>
      <span>
        <br />
        Garantir um suporte completo, nos trâmites nacionais e internacionais com segurança, eficácia e responsabilidade. Adotar um gerenciamento de qualidade, integrado a todas nossas atividades, e cujas diretrizes de busca constante por melhorias sejam um objeto compartilhado por todos nossos colaboradores.
    </span>
    </div>
  </div>