import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politica-de-qualidade',
  templateUrl: './politica-de-qualidade.component.html',
  styleUrls: ['./politica-de-qualidade.component.css']
})
export class PoliticaDeQualidadeComponent implements OnInit {
  slides = [
    {
      img: "/assets/images/banners/Codeli-banner-web-QUALIDADE.png",
      img_mobile: "/assets/images/banners/Codeli-banner-mobile-QUALIDADE.png"
    }
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};

  slickInit(e) {
    console.log('slick initialized');
  }

  constructor() { }

  ngOnInit(): void {
  }

}
