import { Component, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-codeli-system',
  templateUrl: './codeli-system.component.html',
  styleUrls: ['./codeli-system.component.css']
})
export class CodeliSystemComponent implements OnInit {
  showMenu1: boolean = false;
  showMenu2: boolean = false;
  showMenu3: boolean = false;
  showMenu4: boolean = false;
  showMenu5: boolean = false;
  showMenu6: boolean = false;

  slides = [
    {
      img: "/assets/images/banners/Codeli-banner-web-CODELI_SYSTEM.png",
      img_mobile: "/assets/images/banners/Codeli-banner-mobile-CODELI_SYSTEM.png"
    }
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};

  slickInit(e) {
    console.log('slick initialized');
  }

  featureList = [
    { 
      image: '/assets/images/servicos/codeli-system/acesso-com-senha.png',
      title: 'Acesso com senha',
      direction: 'up'
    },
    { 
      image: '/assets/images/servicos/codeli-system/localizacao-rastreamento.png',
      title: 'Localização e rastreamento',
      direction: 'down'
    },
    { 
      image: '/assets/images/servicos/codeli-system/monitoramento-pedido.png',
      title: 'Monitoramento de pedido',
      direction: 'up'
    },
    { 
      image: '/assets/images/servicos/codeli-system/monitoramento-desvio.png',
      title: 'Monitoramento de desvio',
      direction: 'down'
    },
    { 
      image: '/assets/images/servicos/codeli-system/catalogo-produtos.png',
      title: 'Catálogo de produtos',
      direction: 'up'
    },
    { 
      image: '/assets/images/servicos/codeli-system/arquivamento-documentos.png',
      title: 'Arquivamento de documentos',
      direction: 'down'
    },
    { 
      image: '/assets/images/servicos/codeli-system/relatorios.png',
      title: 'Relatórios de performance',
      direction: 'up'
    },
    { 
      image: '/assets/images/servicos/codeli-system/download.png',
      title: 'Download de arquivos em Excel e PDF',
      direction: 'down'
    },
  ];

  @ViewChild('About', { static: false })
  private About: ElementRef<HTMLDivElement>;
  isAboutScrolledIntoView: boolean;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.About) {
      const rect = this.About.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isAboutScrolledIntoView = topShown && bottomShown;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }
}
