import { Component, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-industria',
  templateUrl: './industria.component.html',
  styleUrls: ['./industria.component.css']
})
export class IndustriaComponent implements OnInit {
  slides = [
    {
      img: "/assets/images/banners/Codeli-banner-web-INDUSTRIA.png",
      img_mobile: "/assets/images/banners/Codeli-banner-mobile-INDUSTRIA.png"
    }
  ];

  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};

  slickInit(e) {
    console.log('slick initialized');
  }

  @ViewChild('Farmaceutica', { static: false })
  private Farmaceutica: ElementRef<HTMLDivElement>;
  isFarmaceuticaScrolledIntoView: boolean;

  @ViewChild('Automotivo', { static: false })
  private Automotivo: ElementRef<HTMLDivElement>;
  isAutomotivoScrolledIntoView: boolean;

  @ViewChild('Food', { static: false })
  private Food: ElementRef<HTMLDivElement>;
  isFoodScrolledIntoView: boolean;

  @ViewChild('Oleo', { static: false })
  private Oleo: ElementRef<HTMLDivElement>;
  isOleoScrolledIntoView: boolean;

  @ViewChild('Tecnologia', { static: false })
  private Tecnologia: ElementRef<HTMLDivElement>;
  isTecnologiaScrolledIntoView: boolean;

  @ViewChild('Cargos', { static: false })
  private Cargos: ElementRef<HTMLDivElement>;
  isCargosScrolledIntoView: boolean;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.Farmaceutica) {
      const rect = this.Farmaceutica.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isFarmaceuticaScrolledIntoView = topShown && bottomShown;
    }

    if (this.Automotivo) {
      const rect = this.Automotivo.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isAutomotivoScrolledIntoView = topShown && bottomShown;
    }

    if (this.Food) {
      const rect = this.Food.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 600;
      this.isFoodScrolledIntoView = topShown && bottomShown;
    }

    if (this.Oleo) {
      const rect = this.Oleo.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 600;
      this.isOleoScrolledIntoView = topShown && bottomShown;
    }

    if (this.Tecnologia) {
      const rect = this.Tecnologia.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isTecnologiaScrolledIntoView = topShown && bottomShown;
    }

    if (this.Cargos) {
      const rect = this.Cargos.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isCargosScrolledIntoView = topShown && bottomShown;
    }
  }

  contentList = [
    { 
      title: "Farmacêutica",
      title_en: "[EN] Farmacêutica",
      content: "Soluções especiais para atender os requisitos regulatórios.<br /><br />Atuando na indústria farmacêutica há mais de 30 anos, a CODELI entende a importância de oferecer soluções logísticas adequadas de confiança, e eficiência.<br /><br />Nossos profissionais são devidamente treinados regularmente e ajudam na implementação de soluções logísticas inovadoras, que atendem todos os requisitos com excelência operacional.<br /><br />Soluções específicas de supply chain, para atender os desafios de seus negócios.<br /><br />A indústria farmacêutica não para de evoluir. Com base em nossa experiência, somos aptos a desenvolver soluções de logísticas sob medida, para atender os requisitos específicos de seus produtos.<ul><li>Produtos farmacêuticos;</li><li>Equipamentos médicos e de diagnóstico;</li><li>Produtos altamente especializados;</li><li>Maquinários e partes e peças;</li><li>Produtos controlados;</li><li>Mercadorias perigosas.</li></ul>",
      image: "/assets/images/industrias/farmaceutica.png",
      color: "white",
      row_type: "row",
      text_type: "text"
    },
    { 
      title: "Automotivo",
      title_en: "[EN] Automotivo",
      content: "Serviços especializados para os desafios da evolução de sua <em>supply chain</em>;<br /><br />Fornecedores automotivos, montadoras, importadoras, exportadoras, distribuidoras ou provedores de mobilidade... <br /><br />O bom desempenho da logística é um item fundamental para sua competitividade, com requisitos específicos em termos de confiabilidade e qualidade, por isso <strong>garantimos nossa experiência em cada etapa de sua cadeia de suprimentos, em cada etapa do processo</strong>.<br /><br />Análise contínua e adequação das suas soluções logísticas de transporte, nacional e internacional.<br /><br />Desde partes e peças, itens pré-montados, até veículos novos, nossos profissionais desenham e implementam soluções logísticas, para atender aos desafios dos negócios voltados à indústria automotiva.",
      image: "/assets/images/industrias/automotivo.png",
      color: "darkest-blue",
      row_type: "reverse-row",
      text_type: "text-reverse"
    },
    { 
      title: "Food and Beverage",
      title_en: "[EN] Alimentícia",
      content: "Soluções especiais de logística, para garantir a confiabilidade e eficiência da sua cadeia de suprimentos. <br /><br />Fluxograma de soluções de supply chain personalizadas, para o setor de Alimentos e Bebidas.<br /><br />A visibilidade de ponta-a-ponta em sua cadeia de suprimentos é extremamente importante. Com base na nossa experiência, no setor de Alimentos e Bebidas, desenvolvemos uma solução dedicada para o seu negócio. Fornecemos soluções logísticas competitivas, contribuindo para a sua política de Responsabilidade Social Corporativa.<br /><br />Em um mundo de negócios com rápida mudança e com grandes mercados regulatórios, as cadeias de suprimentos de alimentos e bebidas se tornam cada vez mais complexas, impulsionadas por um público cada vez mais exigente.",
      image: "/assets/images/industrias/alimenticia.png",
      color: "dark-blue",
      row_type: "row",
      text_type: "text"
    },
    { 
      title: "Óleo e gás",
      title_en: "[EN] Óleo e gás",
      content: "Especialização para atender sem limites, os desafios de sua cadeia de suprimentos.<br /><br />Oferecemos soluções completas, eficientes e seguras, com foco na proteção ambiental. <br /><br />Como cada projeto é único, nossos especialistas desenham e monitoram a solução adequada, adaptada ao seu projeto para garantir o sucesso de seu negócio.<br /><br />Gestão especializada de seus projetos através de um contato único conectado diretamente com nossos especialistas;<br /><br />Estudo de viabilidade: nossos especialistas analisam, desenham e implementam soluções considerando as regulamentações atuais, e especificações do produto, no que diz respeito aos assuntos aduaneiros ou qualquer outro assunto específico, que envolve seu projeto como todo;<br /><br />Equipamentos sob demanda para cada tipo de transporte;<br /><br />Transporte multimodal (desde uma pequena embarcação, marítimo, aéreo ou rodoviário) qualquer que seja o peso, tamanho e tipo de produto de seus processos;<br /><br />Otimização e gestão de operações aduaneiras especiais;<br /><br />Monitoramento de operações em tempo real considerando as normas de segurança de cada trecho.",
      image: "/assets/images/industrias/oleo_gas.png",
      color: "blue",
      row_type: "reverse-row",
      text_type: "text-reverse"
    },
    { 
      title: "Tecnologia",
      title_en: "[EN] Tecnologia",
      content: "Soluções que garantem a boa performance, segurança e agilidade de sua cadeia de suprimentos.<br /><br />Produtos delicados e de alto valor agregado, ou ainda de ciclo de vida curto, os requerimentos do setor de tecnologia são muito específicos.<br /><br />Como especialistas em supply chain para o setor de equipamentos de tecnologia, podemos oferecer soluções especializadas para sua logística.",
      image: "/assets/images/industrias/tecnologia.png",
      color: "soft-blue",
      row_type: "row",
      text_type: "text"
    },
    { 
      title: "Cargas projeto e equipamentos pesados",
      title_en: "[EN] Cargas projeto e equipamentos pesados",
      content: "Soluções logísticas eficientes de porta a porta.<br /><br />Seja qual for o seu projeto para transporte: ônibus, máquinas agrícolas, vagões de trem; partes para aviação, mineração, equipamentos de construção, maquinários ou até mesmo barco, nós gerenciamos soluções porta a porta, de confiança, e seguras para atender as necessidades de seus clientes, em escala mundial.<br /><br />Através de nossas soluções logísticas, cobrimos todos os aspectos de sua cadeia de suprimentos, seja a entrega de componentes do estoque para suas plantas, seja a distribuição de produtos acabados, a entrega de peças de reposição ou logística de cargas especiais, até a entrega de mercadorias pesadas e superdimensionadas.",
      image: "/assets/images/industrias/cargas.png",
      color: "softest-blue",
      row_type: "reverse-row",
      text_type: "text-reverse"
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  
}
