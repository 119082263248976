<div>
  <ngx-slick-carousel class="carousel"
  #slickModal="slick-carousel"
  [config]="slideConfig"
  (init)="slickInit($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <img class="desktop" src="{{ slide.img }}" alt="" width="100%">
      <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%">
    </div>
  </ngx-slick-carousel>
</div>

<section id="about-group">
  <div class="content">
    <div class="wrapper_content">
      <app-missao></app-missao>
      <app-visao></app-visao>
      <app-valores></app-valores>
    </div>
  </div>
</section>

<section #Qualidade>
  <div class="content darkest-blue">
    <div class="wrapper_content">
      <article class="reverse-row">
        <div class="text-reverse right" [ngClass]="{ 'active-right' : isQualidadeScrolledIntoView }">
          <div>
            <h1 class="talign-left">
              <span>
                Política de qualidade:
              </span>
            </h1>
            <div>
              Garantir um suporte completo, nos trâmites nacionais e internacionais com segurança, eficácia e responsabilidade. Adotar um gerenciamento de qualidade, integrado a todas nossas atividades, e cujas diretrizes de busca constante por melhorias sejam um objeto compartilhado por todos nossos colaboradores.
            </div>
    
          </div>
        </div>
    
        <div class="img left" [ngClass]="{ 'active-left': isQualidadeScrolledIntoView }">
          <div class="session-image">
            <img src="/assets/images/home/politica.png" alt="">
          </div>
        </div>
      </article>
    </div>
  </div>
</section>

<section>
  <div #Requisitos class="content blue">
    <div class="wrapper_content">
      <article class="row">
        <div class="text left" [ngClass]="{ 'active-left' : isRequisitosScrolledIntoView }">
          <div>
            <h1 class="talign-left">
              <span>
                Requisitos do cliente:
              </span>
            </h1>
            <div>
              Informação rápida e segura, presença nacional e internacional, qualidade no desembaraço aduaneiro junto aos órgãos anuentes, logística nacional e internacional integrada, otimização de custos e prestação de contas eficiente.
            </div>
    
          </div>
        </div>
    
        <div class="img right" [ngClass]="{ 'active-right': isRequisitosScrolledIntoView }">
          <div class="session-image">
            <img src="/assets/images/home/requisitos.png" alt="">
          </div>
        </div>
      </article>
    </div>
  </div>
</section>

<section>
  <div #Objetivos class="content dark-blue ">
    <div class="wrapper_content">
      <article class="reverse-row">
        <div class="text-reverse right" [ngClass]="{ 'active-right' : isObjetivosScrolledIntoView }">
          <div>
            <h1 class="talign-left">
              <span>
                Objetivos da qualidade:
              </span>
            </h1>
            <div>
              Ser um centro de excelência no conhecimento e gerenciamento de informações relacionadas ao comércio exterior, de forma a exceder as expectativas dos clientes, aplicando sua expertise na atuação nos âmbitos nacional e internacional.
            </div>
    
          </div>
        </div>
    
        <div class="img left" [ngClass]="{ 'active-left': isObjetivosScrolledIntoView }">
          <div class="session-image">
            <img src="/assets/images/home/objetivos.png" alt="">
          </div>
        </div>
      </article>
    </div>
  </div>
</section>

<section #Responsabilidade>
  <div class="soft-blue">
    <div class="wrapper_content">
      <article class="row">
        <div class="text">
          <div class="left" [ngClass]="{ 'active-left': isResponsabilidadeScrolledIntoView }">
            <h1 class="talign-left">
              <span>Responsabilidade Socioambiental:</span>
            </h1>
            <div>
              <div class='tableCodeli'>
                <div class='row_table'>
                  <div class='icon_table'><img src="/assets/images/co.png" /></div>
                  <div class='text_table'>operando com a natureza</div>
                </div><div class='row_table'>
                  <div class='icon_table'><img src="/assets/images/de.png" /></div>
                  <div class='text_table'>fendendo a sustentabilidade</div>
                </div>
                <div class='row_table'>
                  <div class='icon_table'><img src="/assets/images/li.png" /></div>
                  <div class='text_table'>berando energias positivas no planeta!</div>
                </div>
              </div>
            </div>
    
          </div>
        </div>
    
        <div class="img right" [ngClass]="{ 'active-right': isResponsabilidadeScrolledIntoView }">
          <div class="session-image">
            <img src="/assets/images/home/responsabilidade.png" alt="">
          </div>
        </div>
      </article>
    </div>
  </div>
</section>

<section class="parallax"></section>

<section class="content" #Contratar>
  <div class="wrapper_content up" [ngClass]="{ 'active-up': isContratarScrolledIntoView }">
    <h1 class="talign-center">Por que nos contratar:</h1>
    <div class='tableCodeli'>
      <div class='row_logo'>
        <div class='icon_logo'><img src="/assets/images/codeli_logo-icon.png" /></div>
        <div class='text_logo'>60 anos de mercado, total expertise no desembaraço aduaneiro;</div>
      </div>
      <div class='row_logo'>
        <div class='icon_logo'><img src="/assets/images/codeli_logo-icon.png" /></div>
        <div class='text_logo'>Especialização | serviço diferenciado, na industria farmacêutica;</div>
      </div>
      <div class='row_logo'>
        <div class='icon_logo'><img src="/assets/images/codeli_logo-icon.png" /></div>
        <div class='text_logo'>Equipe dedicada e devidamente treinada; </div>
      </div>
      <div class='row_logo'>
        <div class='icon_logo'><img src="/assets/images/codeli_logo-icon.png" /></div>
        <div class='text_logo'>Serviço de acompanhamento porta a porta integrados (desembaraço e logística);</div>
      </div>
      <div class='row_logo'>
        <div class='icon_logo'><img src="/assets/images/codeli_logo-icon.png" /></div>
        <div class='text_logo'>Qualidade nos serviços prestados como nosso principal objetivo. </div>
      </div>
    </div>
  </div>
</section>
