import { Component, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';


@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {

  success = false;

  public sendEmail(e: Event) {
    e.preventDefault();
    emailjs.sendForm('service_ufa8r34', 'template_16d2dfo', e.target as HTMLFormElement, 'user_Hr2eo5Jkg02jFt2L2Ebwo')
      .then((result: EmailJSResponseStatus) => {
        if (result.text === 'OK') {
          this.success = true;
        }
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

  slides = [
    {
      img: "/assets/images/banners/Codeli-banner-web-CONTATO.png",
      img_mobile: "/assets/images/banners/Codeli-banner-mobile-CONTATO.png"
    }
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};

  slickInit(e) {
    console.log('slick initialized');
  }

  @ViewChild('Form', { static: false })
  private Form: ElementRef<HTMLDivElement>;
  isFormScrolledIntoView: boolean;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.Form) {
      const rect = this.Form.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isFormScrolledIntoView = topShown && bottomShown;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
