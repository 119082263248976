<div>
    <ngx-slick-carousel class="carousel" 
    #slickModal="slick-carousel" 
    [config]="slideConfig" 
    (init)="slickInit($event)">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <img class="desktop" src="{{ slide.img }}" alt="" width="100%" />
      <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%" />
    </div>
    </ngx-slick-carousel>
</div>

<div class="content" #Form>
    <div class="wrapper_content">
      <div class="up" [ngClass]="{ 'active-up': isFormScrolledIntoView }">
        <h1>
          <span>Ouvidoria</span>
        </h1>
          <form class="contact-form" (submit)="sendEmail($event)" *ngIf="!success">
              <input type="text" name="assunto" placeholder="Assunto">
              <textarea name="message" placeholder="Mensagem"></textarea>
              <input class="btn" type="submit" value="Enviar denúncia">
          </form>

          <div class="success-box" *ngIf="success">
            Mensagem enviada com sucesso! Em breve entraremos em contato
          </div>
      </div>
    </div>
  </div>