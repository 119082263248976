import { Component, ViewChild, ElementRef, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-desembaraco-aduaneiro',
  templateUrl: './desembaraco-aduaneiro.component.html',
  styleUrls: ['./desembaraco-aduaneiro.component.css']
})
export class DesembaracoAduaneiroComponent implements OnInit {

  slides = [
    {
      img: "/assets/images/banners/Codeli-banner-web-DESEMBARACO_ADUANEIRO.png",
      img_mobile: "/assets/images/banners/Codeli-banner-mobile-DESEMBARACO_ADUANEIRO.png"
    }
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1};

  slickInit(e) {
    console.log('slick initialized');
  }

  @ViewChild('About', { static: false })
  private About: ElementRef<HTMLDivElement>;
  isAboutScrolledIntoView: boolean;

  @ViewChild('Expertise', { static: false })
  private Expertise: ElementRef<HTMLDivElement>;
  isExpertiseScrolledIntoView: boolean;

  @ViewChild('Service', { static: false })
  private Service: ElementRef<HTMLDivElement>;
  isServiceScrolledIntoView: boolean;

  @ViewChild('Entrega', { static: false })
  private Entrega: ElementRef<HTMLDivElement>;
  isEntregaScrolledIntoView: boolean;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.About) {
      const rect = this.About.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isAboutScrolledIntoView = topShown && bottomShown;
    }

    if (this.Expertise) {
      const rect = this.Expertise.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isExpertiseScrolledIntoView = topShown && bottomShown;
    }

    if (this.Service) {
      const rect = this.Service.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isServiceScrolledIntoView = topShown && bottomShown;
    }

    if (this.Entrega) {
      const rect = this.Entrega.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= -2100;
      const bottomShown = rect.bottom <= window.innerHeight + 400;
      this.isEntregaScrolledIntoView = topShown && bottomShown;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
