<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)">
  <div ngxSlickItem *ngFor="let slide of slides" class="slide">
    <img class="desktop" src="{{ slide.img }}" alt="" width="100%">
    <img class="mobile" src="{{ slide.img_mobile }}" alt="" width="100%">
  </div>
</ngx-slick-carousel>
<div></div>

<div class="content" #Form>
  <div class="wrapper_content">
    <div class="up" [ngClass]="{ 'active-up': isFormScrolledIntoView }">
      <h1>
        <span>Contato</span>
      </h1>

      <div>
        <span>
          Quer conhecer melhor nossos serviços? Entre em contato conosco pelo formulário abaixo e descubra o que a CODELI pode oferecer para você.</span>
      </div>

      <form class="contact-form" (submit)="sendEmail($event)" *ngIf="!success">
        <input type="text" name="from_name" placeholder="Nome">
        <input type="email" name="user_email" placeholder="E-mail" required>
        <textarea name="message" placeholder="Mensagem"></textarea>
        <input class="btn" type="submit" value="Enviar mensagem">
      </form>

      <div class="success-box" *ngIf="success">
        Mensagem enviada com sucesso! Em breve entraremos em contato
      </div>
    </div>

  </div>
</div>